import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  CssBaseline,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Header from "./Header";
import useUser from "../hooks/useUser";
import OnboardService from "../Pages/Onboarding/onboaring.service";
import { USER_TYPE } from "../constants";
import { Gray, Orange, Shades } from "../Style/colors";
import { getEpoch } from "../utils/formatDate";
import { storeAuthToken, storeLoginToken } from "../redux/slices/authSlice";
import { setSticky, setScrollToTop } from "../redux/slices/LMS.slice";
import {
  LearnerDashboardIcon,
  LearnerDashboardIconActive,
  LearnerLms,
  LearnerLmsActive,
  LearnerProgressTracking,
  LearnerProgressTrackingActive,
  ScheduleIcon,
  ScheduleIconActive,
  LearnerBook,
  LearnerLmsActiveBook,
} from "../assets/logos/logos";
import myProgramsActiveIcon from "../assets/images/myProgramsActive.svg";
import myProgramsInactiveIcon from "../assets/images/myProgramsInactive.svg";
import throttle from "../utils/throttle";

const Layout = (props: any) => {
  const upcomingClassRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(true);
  const refreshToken = useSelector(
    (state: any) => state?.authSlice?.refreshToken
  );
  const dispatch = useDispatch();
  const user = useUser();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const ScrollToTop = useSelector((state: any) => state?.webLMS?.scrollToTop);

  const triggerScroll: any = useSelector(
    (state: any) => state?.mainSidebarSlice?.scroll
  );

  const isMentor = user?.user_type === USER_TYPE.MENTOR;

  function onNavigateToDashboard() {
    navigate(isMentor ? "/dashboard" : "/my_dashboard");
  }

  function onNavigateToPrograms() {
    navigate("/programs");
  }

  function onNavigateToLearners() {
    navigate("/learner");
  }

  function onNavigateToProgressTracking() {
    navigate("/progress");
  }

  function onNavigateToSchedules() {
    navigate(isMentor ? "/schedules" : "/learner_schedules");
  }

  function onNavigateToDemoPrograms() {
    navigate("/suggested-programs");
  }

  useEffect(() => {
    const timer = setInterval(() => {
      (async () => {
        const res = await OnboardService.renewToken(refreshToken);
        if (res?.status) {
          dispatch(storeLoginToken(res?.data?.token));
          dispatch(storeAuthToken({ ...res?.data, iua: getEpoch() }));
        }
      })();
    }, 3540000); // 59 min

    return () => clearInterval(timer);
  });

  let scheduleIconSource = ScheduleIcon;
  if (
    pathname.includes("/schedules") ||
    pathname.includes("/learner_schedules")
  ) {
    scheduleIconSource = ScheduleIconActive;
  }

  const handleSideBarMenuIconsChange = () => {
    let dashboardIconSrc =
      pathname.includes("/dashboard") || pathname.includes("/my_dashboard")
        ? LearnerDashboardIconActive
        : LearnerDashboardIcon;

    return <img src={dashboardIconSrc} alt="LearnerDashboardIcon" />;
  };

  // hide side menu when user is on task details page
  // const hideLayout = pathname.startsWith(`/programs/`) || pathname.startsWith("/learner/");
  const isSwitchDevice = pathname === "/switch-device";
  const hideLayout =
    /^\/programs\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/.test(pathname) ||
    /^\/learner\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/.test(pathname) ||
    isSwitchDevice;

  const suggestedProgramsPath =
    pathname === "/suggested-programs" || pathname === "/suggested-programs/";

  useEffect(() => {
    // @ts-ignore
    upcomingClassRef?.current?.scrollTo(0, 0);
  }, [triggerScroll]);

  useEffect(() => {
    // collapse view of side menu when user is on module listing page
    const isProgramIdOnly =
      /^\/programs\/[a-zA-Z0-9]+$/.test(pathname) ||
      /^\/learner\/[a-zA-Z0-9]+$/.test(pathname) ||
      /^\/suggested-programs\/[a-zA-Z0-9]+$/.test(pathname);
    if (
      pathname.startsWith("/programs/") ||
      pathname.startsWith("/learner/") ||
      pathname.startsWith("/suggested-programs/")
    ) {
      if (isProgramIdOnly) {
        setOpen(false);
      }
    } else {
      setOpen(true);
    }
  }, [pathname]);

  useEffect(() => {
    const handleScroll = throttle(() => {
      const scrollPosition = upcomingClassRef.current?.scrollTop ?? 0;
      if (scrollPosition > 120) {
        dispatch(
          setSticky({
            type: "SET_IS_STICKY",
            payload: true,
          })
        );
      } else if (scrollPosition < 55) {
        dispatch(
          setSticky({
            type: "SET_IS_STICKY",
            payload: false,
          })
        );
      }
    }, 300);

    if (upcomingClassRef.current) {
      upcomingClassRef.current.addEventListener("scroll", handleScroll);
      return () => {
        upcomingClassRef.current?.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (ScrollToTop) {
      upcomingClassRef.current?.scrollTo(0, 0);
      setTimeout(() => {
        dispatch(setScrollToTop(false));
      }, 1000); // Reset the ScrollToTop state after scrolling
    }
  }, [ScrollToTop]);

  return (
    <>
      {!isSwitchDevice && <Header isLoggedIn />}
      <CssBaseline />

      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 62px)",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {/* Drawer */}
        {hideLayout ? null : (
          <Box
            sx={{
              minWidth: 80,
              maxWidth: 256,
              background: Shades["101"],
            }}
            style={{ width: open ? "100%" : "0%" }}
          >
            <List
              sx={{ width: "100%", maxWidth: 256 }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {/* <ListItemButton sx={{ p: '21px 28px' }} onClick={() => setOpen(!open)}>
                <ListItemIcon sx={{ color: "white" }} >{open ? "<" : ">"}</ListItemIcon>
              </ListItemButton> */}
              <ListItemButton
                sx={{ p: "21px 28px" }}
                onClick={onNavigateToDashboard}
              >
                <ListItemIcon>{handleSideBarMenuIconsChange()}</ListItemIcon>

                {open && (
                  <ListItemText
                    primary="Dashboard"
                    sx={{
                      color:
                        pathname.includes("/my_dashboard") ||
                        pathname.includes("/dashboard")
                          ? Orange["600"]
                          : Shades["0"],
                      fontFamily: "var(--fontFamiljen)",
                    }}
                  />
                )}
              </ListItemButton>
              {!isMentor && (
                <ListItemButton
                  sx={{ p: "21px 28px" }}
                  onClick={onNavigateToDemoPrograms}
                >
                  <ListItemIcon>
                    {pathname.includes("/suggested-programs") ? (
                      <img src={LearnerLmsActive} alt="LearnerLmsActive" />
                    ) : (
                      <img src={LearnerLms} alt="LearnerLms" />
                    )}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary="Programs"
                      sx={{
                        color: pathname.includes("/suggested-programs")
                          ? Orange["600"]
                          : Shades["0"],
                        fontFamily: "var(--fontFamiljen)",
                      }}
                    />
                  )}
                </ListItemButton>
              )}
              {!isMentor && (
                <ListItemButton
                  sx={{ p: "21px 28px" }}
                  onClick={onNavigateToPrograms}
                >
                  <ListItemIcon>
                    {pathname.includes("/programs") ? (
                      <img src={myProgramsActiveIcon} alt="LearnerLmsActive" />
                    ) : (
                      <img src={myProgramsInactiveIcon} alt="LearnerLms" />
                    )}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary="My Programs"
                      sx={{
                        color: pathname.includes("/programs")
                          ? Orange["600"]
                          : Shades["0"],
                        fontFamily: "var(--fontFamiljen)",
                      }}
                    />
                  )}
                </ListItemButton>
              )}
              {isMentor && (
                <ListItemButton
                  sx={{ p: "21px 28px" }}
                  onClick={onNavigateToLearners}
                >
                  <ListItemIcon>
                    {/* <MenuBookIcon sx={iconColor} /> */}
                    {pathname.includes("/learner") ? (
                      <img
                        src={LearnerLmsActiveBook}
                        alt="learner"
                        width={24}
                        height={24}
                      />
                    ) : (
                      <img
                        src={LearnerBook}
                        alt="LearnerLms"
                        width={24}
                        height={24}
                      />
                    )}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary="Learners"
                      sx={{
                        color: pathname.includes("/learner")
                          ? Orange["600"]
                          : Shades["0"],
                        fontFamily: "var(--fontFamiljen)",
                      }}
                    />
                  )}
                </ListItemButton>
              )}

              <ListItemButton
                sx={{ p: "21px 28px" }}
                onClick={onNavigateToSchedules}
              >
                {/* <ListItemIcon>
                {pathname.includes("/schedules") ? (
                  <img src={ScheduleIconActive} alt="ScheduleIconActive" />
                ) : pathname.includes("/learner_schedules") ? (
                  <img src={ScheduleIconActive} alt="ScheduleIconActive" />
                ) : (
                  <img src={ScheduleIcon} alt="ScheduleIcon" />
                )}
              </ListItemIcon> */}

                <ListItemIcon>
                  <img src={scheduleIconSource} alt="ScheduleIcon" />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary="Schedules"
                    sx={{
                      color:
                        pathname.includes("/learner_schedules") ||
                        pathname.includes("/schedules")
                          ? Orange["600"]
                          : Shades["0"],
                      fontFamily: "var(--fontFamiljen)",
                    }}
                  />
                )}
              </ListItemButton>

              {!isMentor && (
                <ListItemButton
                  sx={{ p: "21px 28px" }}
                  onClick={onNavigateToProgressTracking}
                >
                  <ListItemIcon>
                    {pathname.includes("/progress") ? (
                      <img
                        src={LearnerProgressTrackingActive}
                        alt="LearnerProgressTrackingActive"
                      />
                    ) : (
                      <img
                        src={LearnerProgressTracking}
                        alt="LearnerProgressTracking"
                      />
                    )}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary="Progress"
                      sx={{
                        color: pathname.includes("/progress")
                          ? Orange["600"]
                          : Shades["0"],
                        fontFamily: "var(--fontFamiljen)",
                      }}
                    />
                  )}
                </ListItemButton>
              )}
            </List>
          </Box>
        )}

        {/* Main Container */}
        <Box
          ref={upcomingClassRef}
          sx={{
            p: isSwitchDevice ? "0" : "0 24px",
            width: "100%",
            paddingRight: "20px",
            maxWidth: {
              xl: "100%",
            },
            height: "100%",
            overflow: "auto",
            mx: "auto",
            backgroundColor:
              hideLayout || suggestedProgramsPath ? Gray["50"] : "",
            margin: "0px",
            boxSizing: "border-box",
          }}
        >
          {props?.children}
        </Box>
      </Box>
    </>
  );
};

export default Layout;
