import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
} from "@reduxjs/toolkit/query/react";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { baseQueryWithReauth } from "../../global";
import {
  toggleLearnerList,
  toggleModuleLoader,
  toggleProgramList,
  toggleProgramProgressLoader,
  toggleTaskLoader,
} from "../slices/LMS.slice";

export const api = createApi({
  reducerPath: "webLMS.services",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "miniPrograms",
    "programListForLearner",
    "programProgress",
    "moduleDetail",
    "taskDetail",
    "programList",
    "schedules",
    "learnerDashboard",
    "mentorDashboard",
    "DaySlot",
    "recordVideo",
    "demoProgram",
  ],
  endpoints: (
    builder: EndpointBuilder<
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
      never,
      "webLMS"
    >
  ) => ({
    webLogin: builder.mutation<any, any>({
      query: (payload: object) => ({
        url: `login`,
        method: "POST",
        body: payload,
      }),
    }),

    sendOtp: builder.mutation<any, any>({
      query: (payload: object) => ({
        url: `send-otp`,
        method: "POST",
        body: payload,
      }),
    }),

    verifyOtp: builder.mutation<any, any>({
      query: (payload: object) => ({
        url: `verify-otp`,
        method: "POST",
        body: payload,
      }),
    }),

    onboardUser: builder.query<any, any>({
      query: () => ({
        url: `onboarding`,
        method: "GET",
      }),
    }),

    renewToken: builder.mutation<any, any>({
      query: (payload: object) => ({
        url: `refresh-token`,
        method: "POST",
        body: payload,
      }),
    }),

    daySlots: builder.query<any, any>({
      query: () => ({
        url: `day-slot-list`,
        method: "GET",
      }),
      // @ts-ignore
      providesTags: ["DaySlot"],
    }),

    updateMentorProfile: builder.mutation<any, any>({
      query: (payload: object) => ({
        url: `update-profile`,
        method: "POST",
        body: payload,
      }),
    }),

    updateMentorAvailableSlots: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: `mentor-slots`,
        method: "PUT",
        body: payload.body,
      }),
      // @ts-ignore
      invalidatesTags: ["DaySlot"],
    }),

    learners: builder.mutation<any, any>({
      query: (payload: object) => ({
        url: `mentor-learner-list`,
        method: "POST",
        body: payload,
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(toggleLearnerList(null));
      },
    }),

    miniPrograms: builder.query<any, any>({
      query: () => ({
        url: `get-programs-list`,
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(toggleProgramList(null));
      },
    }),

    programListForLearner: builder.query<any, any>({
      query: () => ({
        url: `get-programs`,
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(toggleProgramList(null));
      },
      // @ts-ignore
      providesTags: ["getUpdateSlots"],
    }),

    programProgress: builder.query<any, any>({
      query: ({ programId, learnerId }: any) => ({
        url: `get-program-progress/${programId}${learnerId ? "?learner_id=" + learnerId : ""}`,
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(toggleProgramProgressLoader(null));
      },
    }),

    moduleList: builder.query<any, any>({
      query: ({ programId, learnerId }: any) => ({
        url: `get-module-list/${programId}${learnerId ? "?learner_id=" + learnerId : ""}`,
        method: "GET",
      }),
      transformResponse(res: any) {
        return res?.data;
      },
    }),

    moduleDetail: builder.query<any, any>({
      query: ({ moduleId, learnerId }: any) => ({
        url: `get-module-details/${moduleId}${learnerId ? "?learner_id=" + learnerId : ""}`,
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(toggleModuleLoader(null));
      },
    }),

    taskUpdates: builder.query<any, any>({
      query: ({ taskId, learnerId }: any) => ({
        url: `get-daily-update/${taskId}${learnerId ? "?learner_id=" + learnerId : ""}`,
        method: "GET",
      }),
      // @ts-ignore
      // providesTags: ['taskUpdateList'],
      transformResponse(res: any) {
        return res?.data;
      },
    }),

    taskDetail: builder.query<any, any>({
      query: ({ taskId, learnerId }: any) => ({
        url: `get-task-details/${taskId}${learnerId ? "?learner_id=" + learnerId : ""}`,
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(toggleTaskLoader(null));
      },
      // @ts-ignore
      providesTags: ["taskDetail"],
    }),

    programList: builder.query<any, any>({
      query: () => ({
        url: `get-programs`,
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(toggleProgramList(null));
      },
      // @ts-ignore
      providesTags: ["programList"],
      // providesTags: ['programList'],
    }),

    updateStartTask: builder.mutation<any, any>({
      query: (payload: object) => ({
        url: `task-daily-update`,
        method: "POST",
        body: payload,
      }),
      // @ts-ignore
      invalidatesTags: ["taskDetails"],
    }),

    getLatestCode: builder.query<any, any>({
      query: (data: any) => ({
        url: `${data.url}/${data.taskId}`,
        method: "GET",
      }),
    }),

    submitTask: builder.mutation<any, any>({
      query: (payload: object) => ({
        url: `task-daily-update`,
        method: "POST",
        body: payload,
      }),
      // @ts-ignore
      invalidatesTags: ["taskDetails"],
    }),

    getAllLearnerDashboardData: builder.query<any, any>({
      query: (data: any) => ({
        url: `${data?.url}?program_id=${data.id}`,
        method: "GET",
      }),
      // @ts-ignore
      providesTags: ["learnerDashboard", "schedules"],
    }),

    getAllSlotsList: builder.query<any, any>({
      query: (data: any) => ({
        url: `get-all-slots`,
        method: "POST",
        body: data?.body,
      }),
      // @ts-ignore
      providesTags: ["schedules"],
    }),

    getUpcomingClasses: builder.query<any, any>({
      query: (data: any) => ({
        url: `${data?.url}&page=${data?.page}&limit=${data?.limit}`,
        method: "GET",
        // body: data?.body,
      }),
      // @ts-ignore
      providesTags: ["schedules"],
    }),

    cancelSchedule: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: `cancel-schedule-call`,
        method: "POST",
        body: payload.body,
      }),
      // @ts-ignore
      invalidatesTags: ["schedules"],
    }),

    chatList: builder.query<any, any>({
      query: () => ({
        url: `get-chats-list`,
        method: "GET",
      }),
    }),

    getMentorListing: builder.query<any, any>({
      query: () => ({
        url: `get-mentors-list`,
        method: "GET",
      }),
    }),

    updateScheduleCall: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: `schedule-call`,
        method: "POST",
        body: payload.body,
      }),
      // @ts-ignore
      invalidatesTags: ["schedules", "getUpdateSlots"],
    }),

    mentorDashboardData: builder.query<any, any>({
      query: (data: any) => ({
        url: data?.url,
        method: "GET",
      }),
      // @ts-ignore
      providesTags: ["schedules"],
    }),

    mentorPerformanceByProgramData: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `mentor-program-performance`,
        method: "POST",
        body: data,
      }),
      // @ts-ignore
      invalidatesTags: ["mentorDashboard"],
    }),

    programsListForMentor: builder.query<any, any>({
      query: () => ({
        url: `mentor-programs`,
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch }) {
        dispatch(toggleProgramList(null));
      },
    }),
    getProgramModuleWithTask: builder.query<any, any>({
      query: (data: any) => ({
        url: `get-program-module-task?program_id=${data?.programId}`,
        method: "GET",
      }),
    }),
    getAllRecordedVideo: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `live-class/recording-list?batch_id=${data.batchId}&page=${data.page}&limit=${data.limit}`,
          method: "GET",
          // body: data,
        };
      },
      // @ts-ignore
      providesTags: ["recordVideo"],
    }),
    updateRecordedVideoDetails: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: `live-class/recording/${payload.id}`,
        method: "PATCH",
        body: payload.body,
      }),
      // @ts-ignore
      invalidatesTags: ["recordVideo"],
    }),
    updateLearnerScheduleCall: builder.mutation<any, any>({
      query: (payload: any) => ({
        url: `schedule-call`,
        method: "PUT",
        body: payload.body,
      }),
      // @ts-ignore
      invalidatesTags: ["schedules"],
    }),

    // for learner demo program listing
    getDemoProgram: builder.query<any, any>({
      query: (data: any) => ({
        url: `programs?type=${data.type}&limit=${data.limit}&page=${data.page}`,
        method: "GET",
      }),
      // @ts-ignore
      providesTags: ["demoProgram"],
    }),
    // for learner demo program posting
    demoProgramData: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `program/enroll`,
        method: "POST",
        body: data,
      }),
      //  @ts-ignore
      invalidatesTags: ["demoProgram"],
    }),
    // for learner demo program
    getUpcomingBatches: builder.query<any, any>({
      query: (data: any) => ({
        url: `batch/upcoming?program_id=${data.program_id}&count=${data.count}`,
        method: "GET",
      }),
    }),

    GetProgramdetails: builder.query<any, any>({
      query: (data: any) => ({
        url: `program/${data.programId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useWebLoginMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
  useOnboardUserQuery,
  useRenewTokenMutation,
  useDaySlotsQuery,
  useUpdateMentorProfileMutation,
  useUpdateMentorAvailableSlotsMutation,
  useLearnersMutation,
  useMiniProgramsQuery,
  useProgramListForLearnerQuery,
  useProgramProgressQuery,
  useModuleListQuery,
  useModuleDetailQuery,
  useTaskUpdatesQuery,
  useTaskDetailQuery,
  useProgramListQuery,
  useUpdateStartTaskMutation,
  useSubmitTaskMutation,
  useGetUpcomingClassesQuery,
  useCancelScheduleMutation,
  useGetMentorListingQuery,
  useUpdateScheduleCallMutation,
  useGetAllSlotsListQuery,
  useUpdateLearnerScheduleCallMutation,
  useMentorDashboardDataQuery,
  useProgramsListForMentorQuery,
  useMentorPerformanceByProgramDataMutation,
  useGetAllLearnerDashboardDataQuery,
  useGetLatestCodeQuery,
  useGetAllRecordedVideoQuery,
  useUpdateRecordedVideoDetailsMutation,
  useGetProgramModuleWithTaskQuery,
  useGetDemoProgramQuery,
  useDemoProgramDataMutation,
  useGetUpcomingBatchesQuery,
  useGetProgramdetailsQuery,
} = api;
