import Schedules from "../Pages/Schedule/Schedules";
import { lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";

import Store from "../Pages/LMS/programs/Store";
import ScheduleAvailability from "../Pages/Schedule/ScheduleAvailability";
import LearnerSchedules from "../Pages/LearnerSchedule/LearnerSchedules";
import ScheduleClasses from "../Pages/LearnerSchedule/ScheduleClasses";

const Lms = lazy(() => import("../Pages/LMS/programs/Lms"));
const SeeAllPrograms = lazy(
  () => import("../Pages/LMS/programs/SeeAllPrograms")
);
const PaymentsAndBilling = lazy(
  () => import("../Pages/settings/PaymentsAndBilling/PaymentsAndBilling")
);
const Products = lazy(() => import("../Pages/settings/Products"));
const RolesAndPermission = lazy(
  () => import("../Pages/settings/RolesAndPermissions/RolesAndPermission")
);
const Account = lazy(() => import("../Pages/settings/Account/Account"));
const LmsParent = lazy(() => import("../Pages/LMS/programs/LmsParent"));
const UserManagement = lazy(() => import("../Pages/Users/UserManagement"));
const Contact = lazy(() => import("../Pages/Contact"));
const SeeAllSuggestedPrograms = lazy(
  () => import("../Pages/LMS/programs/SeeAllSuggestedPrograms")
);
const Home = lazy(() => import("../Pages/Home/Home"));
const Modules = lazy(() => import("../Pages/LMS/modules/Modules"));
const PageNotFound = lazy(() => import("../Pages/404/PageNotFound"));
const ProgressTracking = lazy(
  () => import("../Pages/ProgressTracking/ProgressTracking")
);

const Dashboard = lazy(() => import("../Pages/Dashboard"));
const DashboardLearner = lazy(
  () => import("../Pages/Dashboard/DashboardLearner")
);
const ProgramListPage = lazy(() => import("../Pages/Programs"));
const ProgramDetailPage = lazy(() => import("../Pages/ProgramDetails"));
const ProgramCodePage = lazy(
  () => import("../Pages/ProgramDetails/ProgramCodePage")
);
const StudentProgressTracking = lazy(
  () => import("../Pages/StudentProgressTracking")
);
const ProgramDetails = lazy(() => import("../Pages/Mentor/ProgramDetails"));

const LearnerListPage = lazy(() => import("../Pages/Mentor/Mentor"));
const LearnerProgressTracking = lazy(
  () => import("../Pages/Mentor/Learner/ProgressTracking")
);
const ModuleList = lazy(() => import("../Pages/Programs/ModuleList"));
const RecordedVideo = lazy(() => import("../Pages/RecordedVideo"));
const SuggestedPrograms = lazy(() => import("../Pages/Programs/SuggestedPrograms"));
const SeeAllDemoPrograms = lazy(
  () => import("../Pages/Programs/SeeAllDemoPrograms")
);
const SwitchDevice = lazy(() => import("../Pages/SwitchDevice"));
const ViewDetailsPage = lazy(
  () => import("../Pages/Programs/ProgramDetails/ProgramDetail")
);

const RoutesComponent = () =>
  useRoutes([
    {
      path: "/",
      element: <Navigate replace to="/admin/home" />,
    },
    {
      path: "switch-device",
      element: <SwitchDevice />,
    },
    {
      path: "/admin/home",
      element: <Home />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "my_dashboard",
      element: <DashboardLearner />,
    },
    {
      path: "/learner",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <LearnerListPage />,
        },
        {
          path: ":learnerId",
          element: <LearnerProgressTracking />,
          children: [
            {
              path: ":moduleId",
              element: <ProgramDetails />,
              children: [
                {
                  path: "code",
                  element: <ProgramCodePage />,
                },
                {
                  path: "recorded-video",
                  element: <RecordedVideo />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/progress",
      element: <StudentProgressTracking />,
      children: [
        {
          path: ":programId",
          element: <StudentProgressTracking />,
        },
      ],
    },
    {
      path: "/schedules",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <Schedules />,
        },
        {
          path: "scheduleavailability",
          element: <ScheduleAvailability />,
        },
      ],
    },

    {
      path: "/learner_schedules",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <LearnerSchedules />,
        },
        {
          path: "schedule_classes",
          element: <ScheduleClasses />,
        },
      ],
    },

    {
      path: "/suggested-programs",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <SuggestedPrograms />,
        },
        {
          path: "all-programs",
          element: <SeeAllDemoPrograms />,
        },
        {
          path: ":programId",
          element: <ViewDetailsPage />,
        },
      ],
    },

    {
      path: "/programs",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <ProgramListPage />,
        },
        {
          path: ":programId",
          element: <Outlet />,
          children: [
            {
              path: "",
              element: <ModuleList />,
            },
            {
              path: ":moduleId",
              element: <ProgramDetailPage />,
            },
            {
              path: "code",
              element: <ProgramCodePage />,
            },
            {
              path: "details",
              element: <ProgramDetailPage />,
            },
            {
              path: "recorded-video",
              element: <RecordedVideo />,
            },
          ],
        },
      ],
    },
    {
      path: "/progress",
      element: <StudentProgressTracking />,
    },
    {
      path: "admin/lms",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <LmsParent />,
          children: [
            {
              path: "",
              element: <Lms />,
            },
            {
              path: ":stack/:id",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <Modules />,
                },
              ],
            },
            {
              path: "myprograms",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <SeeAllPrograms />,
                },
                {
                  path: "see-all-suggested-programs",
                  element: <SeeAllSuggestedPrograms />,
                },
              ],
            },
            {
              path: "suggestedprograms",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <SeeAllSuggestedPrograms />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/admin/progress-tracking",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <ProgressTracking />,
        },
      ],
    },
    {
      path: "/admin/user-management",
      element: <UserManagement />,
    },
    {
      path: "/admin/store",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <Store />,
        },
      ],
    },
    {
      path: "/admin/contact",
      element: <Contact />,
    },
    {
      path: "/admin/settings",
      element: <Outlet />,
      children: [
        {
          path: "account",
          element: <Account />,
        },
        {
          path: "roles",
          element: <RolesAndPermission />,
        },
        {
          path: "feedback",
          element: <Products />,
        },
        {
          path: "payment",
          element: <PaymentsAndBilling />,
        },
      ],
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);

export default RoutesComponent;
