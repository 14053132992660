export const Shades = {
  0: "#FFF",
  100: "#000",
  101: "#100F0F",
  200: "#F5F4F4",
  300: "#444444",
  201: "#ffffff61",
  1000: "#00000000",
  2000: "#ffffff27",
};

export const Gray = {
  10: "#CECACA",
  50: "#F5F4F4",
  60: "#00000033",
  100: "#ECEAEA",
  150: "#5F5959",
  200: "#928B8B",
  250: "#8B8B8B",
  300: "#544F4F",
  400: "#4A4545",
  500: "#2A2828",
  600: "#746D6D",
  700: "#3F3B3B",
  800: "#888181",
  900: "#100F0F",
  1000: "#201E1E",
  1100: "#D8D5D5",
  1200: "#696363",
  1300: "#9C9696",
};

export const Orange = {
  50: "#FCE3D9",
  100: "#FDF1EC",
  150: "#D27979",
  200: "#F7B8A1",
  250: "#FAD4C7",
  300: "#F5AA8E",
  350: "#C7A193",
  400: "#EAD5CD",
  450: "#FCE6DD",
  500: "#EF7143",
  600: "#EC5F2A",
  700: "#D2797930",
  800: "#71250A",
};

export const Green = {
  30: "#D0FFF3",
  40: "#BFF5E7",
  50: "#C5EEE3",
  100: "#09C39399",
  200: "#09C493",
  300: "#06D6A0",
  400: "#06d6a033",
};

export const Yellow = {
  50: "#FEF1D7",
  80: "#EFE1B8",
  100: "#FAAD15",
  200: "#F0A205",
  300: "#F8B736",
  400: "#FBC14B",
  900: "#895f0b",
};

export const Red = {
  10: "#FDCACA",
  50: "#F9E4E4",
  500: "#DA2525",
};

export const Blue = {
  400: "#105CF0",
  500: "#3362DD",
};

export const ChartColor = {
  100: "#06D6A0",
  200: "#FCC85F",
  300: "#EC4239",
  400: "#F5F4F4",
  500: "#FDD587",
};
