import { useState } from "react";
import { Box, Tab, Tabs, Typography, Breadcrumbs } from "@mui/material";
import { FONT_FAMILY, FONT_WEIGHT } from "../../constants";
import { Shades } from "../../Style/colors";
import { baseTextStyle } from "../../Style/base";
import Buttons from "../../components/reusables/Buttons";
import { useNavigate } from "react-router-dom";
import styles from "./learnerSchedule.module.css";
import SchedulesHistory from "./SchedulesHistory";
import UpcomingSchedule from "./UpcomingSchedule";
import { ClockIcon } from "../../assets/logos/logos";
import { BootstrapTooltip } from "../Users/UserManagement";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const doubtSession = "Every week 15 minutes will be credited for the doubt session.";

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 0, py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabStyle = {
  textTransform: "capitalize",
};

const LearnerSchedules = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleScheduleAvailability = () => {
    navigate("/learner_schedules/schedule_classes");
  };
  return (
    <Box sx={{ py: "1rem" }}>
      <Box className={styles.breadBox}>
        <Breadcrumbs>
          <Typography
            sx={baseTextStyle(
              FONT_FAMILY.BODY,
              FONT_WEIGHT.MEDIUM,
              "14px",
              Shades["101"]
            )}
          >
            My Classes
          </Typography>
        </Breadcrumbs>
        <Box display="flex" gap="11px" alignItems="center">
          <Box
            padding="6px 12px"
            display="flex"
            alignItems="center"
            width="fit-content"
            borderRadius="8px"
            border="1px solid var(--orangeDisabled)"
            bgcolor="var(--themeOrangeLight)"
          >
            <BootstrapTooltip title={doubtSession} placement="bottom">
              <Typography
                fontSize="14px"
                fontWeight="500"
                fontFamily="var(--fontFamiljen)"
                color="var(--percentageTextColor)"
              >
                15 min
              </Typography>
            </BootstrapTooltip>
            <img src={ClockIcon} alt="ClockIcon" />
          </Box>
          <Buttons
            name="Schedule Doubt Class"
            variant="contained"
            size="medium"
            className={styles.scheduleClassesBtn}
            type="submit"
            onClick={handleScheduleAvailability}
          />
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="upcoming schedules"
          >
            <Tab label="Upcoming" {...a11yProps(0)} sx={{ ...tabStyle }} />
            <Tab label="History" {...a11yProps(1)} sx={{ ...tabStyle }} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <UpcomingSchedule />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SchedulesHistory />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default LearnerSchedules;
