export const BILLINGANDPAYMENT = "Billing & Payment";
export const REACTJS = "ReactJS";
export const ANGULAR = "Angular";
export const ACCOUNT = "Account";
export const deleteBatchCode = 3105;
export const addProgramCode = 3002;
export const addProgramCodeInside = 3003;
export const updateProgramCode = 3097;
export const errorProgramCode = 3066;
export const editBatchSuccessCode = 3103;
export const canUpdateBatchCode = 3111;
export const editBatchErrCode = 3100;
export const chunkSizeProgressTracking = 15;
export const chunkSizeMentor = 12;
export const chunkSizeMentorTooltip = 10;
export const schedulewholeText = 55;
export const updateSlotSuccessCode = 4012;
export const updateSlotEditCode1 = 4061;
export const updateSlotEditCode2 = 4068;
export const updateSlotErrCode1 = 4074;
export const updateSlotErrCode2 = 4073;
export const updateSlotErrCode3 = 4072;
export const updateSlotErrCode4 = 3024;
export const updateSlotErrCode5 = 4067;
export const updateSlotErrCode6 = 3112;
export const updateSlotErrCode7 = 4013;
export const notSyncedWithOutlook = 3115;
export const deleteFeedbackText = 40;
export const limit = 10;
export const addBatchSuccessCode = 3101;
export const addBatchErrsCode = 3099;
export const loginOtpErrCode = 3051;
export const loginExpireOtpErrCode = 3050;
export const ModulesStatusCode = {
  ADD: 3007,
  UPDATE: 3006,
};
export const LoginPageStatusCode = {
  NOT_ONBOARDED: 4060,
  INVALID_OTP: 4050,
  INVALID_OTP_SIGNUP: 4091,
  EXPIRED_OTP: 3050,
  WRONG_OTP: 4090,
};
export const startDateErr = "Start date and End date must not be same";
export const startTimeErr = "Start time and End time must not be same";
export const invalidDate = "Invalid Date";
export const meetingType = "live_classes";
export const batchProgramLength = 15;
export const batchStringLength = 12;
export const successAddModules = 3067;
export const LANGUAGE_VERSIONS = {
  javascript: "18.15.0",
  typescript: "5.0.3",
  python: "3.10.0",
  java: "15.0.2",
  csharp: "6.12.0",
  php: "8.2.3",
};

export const CODE_SNIPPETS: any = {
  javascript: `\nfunction greet(name) {\n\tconsole.log("Hello, " + name + "!");\n}\n\ngreet("Alex");\n`,
  typescript: `\ntype Params = {\n\tname: string;\n}\n\nfunction greet(data: Params) {\n\tconsole.log("Hello, " + data.name + "!");\n}\n\ngreet({ name: "Alex" });\n`,
  python: `\ndef greet(name):\n\tprint("Hello, " + name + "!")\n\ngreet("Alex")\n`,
  java: `\npublic class HelloWorld {\n\tpublic static void main(String[] args) {\n\t\tSystem.out.println("Hello World");\n\t}\n}\n`,
  csharp:
    'using System;\n\nnamespace HelloWorld\n{\n\tclass Hello { \n\t\tstatic void Main(string[] args) {\n\t\t\tConsole.WriteLine("Hello World in C#");\n\t\t}\n\t}\n}\n',
  php: "<?php\n\n$name = 'Alex';\necho $name;\n",
};

export const bookFreeDemo = {
  DEMO_BOOKED: 3026,
  DEMO_ALREADY_EXIST: 4098,
  MAIN_PROGRAM_BOOKED: 4101,
};
