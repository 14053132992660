import { JSXElementConstructor, ReactElement, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

const Login = lazy(() => import("../Pages/Login/Login"));
const LoginOtp = lazy(() => import("../Pages/Login/LoginOtp"));
const WebLogin = lazy(() => import("../Pages/StudentLogin"));
const Onboard = lazy(() => import("../Pages/Onboarding"));
const Authorize = lazy(() => import("../Pages/authorize"));

export default function PublicRoutes(): ReactElement<
  any,
  string | JSXElementConstructor<any>
> | null {
  let publicRoutes = useRoutes([
    {
      path: "/authorize",
      element: <Authorize />,
    },
    {
      path: "/web-login",
      element: <WebLogin />,
    },
    {
      path: "/onboard",
      element: <Onboard />,
    },
    {
      path: "/",
      element: <Navigate replace to="/web-login" />,
    },
    {
      path: "/admin",
      element: <Login />,
    },
    {
      path: "/admin/loginotp",
      element: <LoginOtp />,
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },

  ]);

  return publicRoutes;
}
